@import url('https://fonts.googleapis.com/css?family=Cairo');

.table-header{
    background:#E9E4C8;
 }
 .over 
 {
     z-index: 30 ;
     position: absolute;
     bottom:20px;
 }

 .overLeft 
 {
     z-index: 30 ;
     position: absolute;
     bottom:20px;
     left: 20px;
 }


 @media screen and (max-width: 400px) {
    .over {
        width: 100%;
    }
  }
 
  .overMapp 
 {
    z-index: 30 ;
    position: absolute;
    top:100px;
 }


 .loading 
 {
    z-index: 30 ;
    position: absolute;
    display: block;
     margin: 0 auto;
     width:80%;
    align-items: 'center',
 }

